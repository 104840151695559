/**
 * This file sets the webpack public path and is required to be loaded as the first import at the entry point.
 * See more info: https://webpack.js.org/guides/public-path/
 * RELATIVE_ASSET_PATH is set as a webpack plugin - this is the relative path set via setVars
 * We use the __system_context__.meta.url to parse the registered module's import path (from import map) to enable resolution in local and deployed environments.
 */

import {setPublicPath} from '@verily-src/phaf-runtime-helpers';

setPublicPath(String(RELATIVE_ASSET_PATH));
