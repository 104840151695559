import {api} from '@verily-src/phaf-unified-api';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {type api as VerilyMeApi} from '@verily-src/verily-me-api';

export const verilyMeApi = () => api as typeof VerilyMeApi;

export const isVerilyMeBundle = () =>
  window.location.href.includes('me/bundle/consent') && !!verilyMeApi().bundle;

export const isTouchDevice = () =>
  'ontouchstart' in window || navigator.maxTouchPoints > 0;

export const isMobileApp = () =>
  window.location.href.includes('me/consent/mobile');
