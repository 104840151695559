import {AppProvider} from '../../../../../common/mfe/src/app/provider';
import {
  createConsentState,
  StateContext,
} from '../../../../../common/mfe/src/app/state';
import {rpcUtils} from '../../api/utils';
import {AppRoutes} from '../../routes';

type RootProps = {
  name: string;
};

export default function Root(props: RootProps) {
  const consentState = createConsentState();

  return (
    <StateContext.Provider value={consentState}>
      <AppProvider rpcUtils={rpcUtils}>
        <AppRoutes />
      </AppProvider>
    </StateContext.Provider>
  );
}
