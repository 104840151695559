import {lazy} from 'react';
import {Navigate, RouteObject} from 'react-router-dom';
import {ConsentRendererType} from '../../../../common/mfe/src/utils/enums';
import ConsentParticipantRouteWrapper from './ConsentParticipantRouteWrapper';

const ConsentSignPage = lazy(
  () =>
    import('../../../../common/mfe/src/pages/consent-sign-page/ConsentSignPage')
);
const ConsentReviewPage = lazy(
  () =>
    import(
      '../../../../common/mfe/src/pages/consent-review-page/ConsentReviewPage'
    )
);
const ConsentPreviewPage = lazy(
  () =>
    import(
      '../../../../common/mfe/src/pages/consent-preview-view/ConsentPreviewPage'
    )
);
const Callback = lazy(
  () => import('../../../../common/mfe/src/components/callback/Callback')
);
const Error = lazy(
  () => import('../../../../common/mfe/src/components/error/Error')
);

const VERILY_ME_PREFIX = 'me';
const BUNDLE_PREFIX = `${VERILY_ME_PREFIX}/bundle`;

export const protectedRoutes: RouteObject[] = [
  {
    element: (
      <ConsentParticipantRouteWrapper type={ConsentRendererType.SignView} />
    ),
    children: [
      {
        path: `/${BUNDLE_PREFIX}/consent`,
        element: <ConsentSignPage />,
      },
      {
        // Endpoint used for Cypress tests
        path: `/${VERILY_ME_PREFIX}/consent/sign`,
        element: <ConsentSignPage />,
      },
      {
        // Endpoint used for testing with Consent Root-Config
        path: `/consent/${VERILY_ME_PREFIX}/consent/sign`,
        element: <ConsentSignPage />,
      },
    ],
  },
  {
    element: (
      <ConsentParticipantRouteWrapper type={ConsentRendererType.ReviewView} />
    ),
    children: [
      {
        // The mobile path is needed for slightly different rendering in the mobile app.
        // Only the review page of the Consent MFE is used in the mobile app.
        path: `/${VERILY_ME_PREFIX}/consent/mobile?/review`,
        element: <ConsentReviewPage />,
      },
      {
        path: `/${BUNDLE_PREFIX}/consent/review`,
        element: <ConsentReviewPage />,
      },
      {
        // Endpoint used for Cypress tests
        path: `/${VERILY_ME_PREFIX}/consent/review`,
        element: <ConsentReviewPage />,
      },
      {
        // Endpoint used for testing with Consent Root-Config
        path: `/consent/${VERILY_ME_PREFIX}/consent/review`,
        element: <ConsentReviewPage />,
      },
    ],
  },
  {
    element: (
      <ConsentParticipantRouteWrapper type={ConsentRendererType.PreviewView} />
    ),
    children: [
      {
        path: `/${BUNDLE_PREFIX}/consent/preview`,
        element: <ConsentPreviewPage />,
      },
      {
        // Endpoint used for testing with Consent Root-Config
        path: `/consent/${VERILY_ME_PREFIX}/consent/preview`,
        element: <ConsentPreviewPage />,
      },
    ],
  },
  {
    path: `/${BUNDLE_PREFIX}/consent/callback`,
    element: <Callback />,
  },
  {
    path: `/${BUNDLE_PREFIX}/consent/error`,
    element: <Error />,
  },
  {path: '*', element: <Navigate to="." />},
];
