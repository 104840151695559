import {protoBase64} from '@bufbuild/protobuf';
import {ConsentRecord} from '@verily-src/verily1-protos/consent/common/bff/api/v1/consent';
import {Outlet, useSearchParams} from 'react-router-dom';
import {useThrowAsyncError} from '../../../../common/mfe/src/app/hooks';
import {ConsentRendererType} from '../../../../common/mfe/src/utils/enums';
export enum UrlParameter {
  EncodedParams = 'encodedParams',
  ConsentRecordId = 'consentRecordId',
  ConsentId = 'consentId',
  Revision = 'revision',
  Locale = 'locale',
}

type RouteProps = {
  type?: ConsentRendererType;
};

const ConsentParticipantRouteWrapper = (props: RouteProps) => {
  const {type} = props;
  const [params, setParams] = useSearchParams();
  const throwAsyncError = useThrowAsyncError();

  // Verily Me Bundles pass the consent record id via the step parameter
  if (
    window.location.href.includes('me/bundle/consent') &&
    !params.get(UrlParameter.ConsentRecordId)
  ) {
    const step = params.get('step');
    if (!step) {
      throwAsyncError(
        new Error(
          'must have either step or consentRecordId URL parameter for bundle'
        )
      );
      return <></>;
    }
    params.set(UrlParameter.ConsentRecordId, step);
    setParams(params);
  }

  switch (type) {
    case ConsentRendererType.SignView: {
      const retrievedEncodedParams = params.get(UrlParameter.EncodedParams);
      const retrievedConsentRecordId = params.get(UrlParameter.ConsentRecordId);
      if (retrievedEncodedParams) {
        return consentRecordOutlet(retrievedEncodedParams);
      } else if (retrievedConsentRecordId) {
        return consentRecordIdOutlet(retrievedConsentRecordId);
      } else {
        throwAsyncError(
          new Error('cannnot find recordId or encodedParams URL parameter')
        );
        return <></>;
      }
    }
    case ConsentRendererType.ReviewView: {
      const retrievedConsentRecordId = params.get(UrlParameter.ConsentRecordId);
      if (!retrievedConsentRecordId) {
        throwAsyncError(new Error('cannnot find recordId URL parameter'));
        return <></>;
      }

      return <Outlet context={retrievedConsentRecordId} />;
    }
    case ConsentRendererType.PreviewView: {
      const consentId = params.get(UrlParameter.ConsentId);
      const revision = params.get(UrlParameter.Revision);
      const locale = params.get(UrlParameter.Locale);

      if (!consentId) {
        throwAsyncError(new Error('cannnot find consentId URL parameter'));
        return <></>;
      }
      if (!locale) {
        throwAsyncError(new Error('cannnot find locale URL parameter'));
        return <></>;
      }
      if (!revision) {
        throwAsyncError(new Error('cannnot find revision URL parameter'));
        return <></>;
      }

      const revisionNumber = parseInt(revision);
      if (!revisionNumber) {
        throwAsyncError(
          new Error(
            'invalid revision URL parameter, must be int greater than 0'
          )
        );
        return <></>;
      }
      return previewConsentOutlet(consentId, revisionNumber, locale);
    }
    default: {
      throwAsyncError(new Error(`invalid consent renderer type: ${type}`));
      return <></>;
    }
  }
};

function consentRecordOutlet(retrievedEncodedParams: string) {
  return (
    <Outlet
      context={[
        /* consentRecordId = */ undefined,
        ConsentRecord.fromBinary(protoBase64.dec(retrievedEncodedParams)),
      ]}
    />
  );
}

function previewConsentOutlet(
  consentId: string,
  revision: number,
  locale: string
) {
  return <Outlet context={[consentId, revision, locale]} />;
}

function consentRecordIdOutlet(retrievedConsentRecordId: string) {
  return (
    <Outlet
      context={[retrievedConsentRecordId, /* consentRecord = */ undefined]}
    />
  );
}

export default ConsentParticipantRouteWrapper;
